import React from 'react';

export const ProductBoxLoading: React.FC = () => {
  return (
    <div className="product-box-loading">
      <div className="product-box__price"></div>
      <div className="product-box__action"></div>
    </div>
  );
};
